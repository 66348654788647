import { Injectable } from '@angular/core';
import { doc, docData, Firestore } from '@angular/fire/firestore';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Customer } from '@no-kno/core/models/customer.model';

import { environment } from '@no-kno/env/environment';

@Injectable()
export class TenantService {

  customer!: string;


  constructor(
    private firestore: Firestore
  ) {
    this.customer = this.parseCustomer(location.host);
  }


  parseCustomer(host: string): string {
    if (location.host === 'localhost:4200') {
      return 'nokno-playground';
    }

    const result = environment.customer.regex.exec(host);
    const defaultCustomer = 'nokno';
    if (result?.[1]) {
      let customer = result[1];

      if (['dev-app', 'app'].includes(customer)) {
        customer = 'capeton';
      } else if( customer === 'pmi' ) {
        customer = 'project-mgmt-inst';
      } else if( customer === 'tsh' ) {
        customer = 'the-social-hub';
      } else if( customer === 'playground' || customer === 'dev-playground' ) {
        customer = 'nokno-playground';
      }

      return customer;
    }

    return defaultCustomer;
  }

  getCustomerData(id: string): Observable<Customer | null> {
    const ref = doc(this.firestore, 'customers', id);
    return docData(ref).pipe(map(data => (data ? ({ id, ...data }) as Customer : null)));
  }

}
