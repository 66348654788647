import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'no-kno-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {
    dragAreaClass = 'dragarea';
    fileName = '';
    translations: any;
    maxFileSizeMessage!: string;
    @Input() fileExt!: string;
    @Input() accept!: string;
    @Input() maxSize = 150;  // Maximum supported size of file in MB, default is 150
    @Input() multipleFiles = true;
    // default message
    @Input() fileTooLargeMessage!: string;
    maxFileCount = 20;
    maxSizeBytes: number = +(this.maxSize) * 1024 * 1024;
    @Output() changeFile = new EventEmitter();

    constructor(
        private snackBar: MatSnackBar,
    ) { }

    ngOnInit() {
        if (typeof this.maxSize === 'undefined') {
            this.maxSizeBytes = 150 * 1024 * 1024;
            this.maxSize = 150;
        } else {
            this.maxSizeBytes = +(this.maxSize) * 1024 * 1024;
        }

        if (!this.fileTooLargeMessage) {
            this.fileTooLargeMessage = `The file size exceeds ${this.maxSize}MB per file`;
        }

       this.maxFileSizeMessage = `Max file size is ${this.maxSize}MB`;
    }

    onFileChange(event: any) {
        const files = <Array<File>>event.target.files;
        if (files.length !== 1 && !this.multipleFiles) {
            return;
        }
        if (!this.isValidFileExtension(files)) {
            this.snackBar.open('Invalid file extension. Use .jpg, .png or .mp4', 'Close');
            return;
        }
        if (this.multipleFiles && files.length > this.maxFileCount) {
            return;
        }

        for (const file of files) {
            if (file.size > this.maxSizeBytes) {
                this.snackBar.open(this.fileTooLargeMessage, 'Close');
                return;
            }
        }

        if (!this.multipleFiles) {
            this.fileName = files[0].name;
        } else {
            this.fileName = files.length + ' files selected';
        }
        this.changeFile.emit(files);
    }

    @HostListener('dragover', ['$event']) onDragOver(event: Event) {
        this.dragAreaClass = 'droparea';
        event.preventDefault();
    }

    @HostListener('dragenter', ['$event']) onDragEnter(event: Event) {
        this.dragAreaClass = 'droparea';
        event.preventDefault();
    }

    @HostListener('dragend', ['$event']) onDragEnd(event: Event) {
        this.dragAreaClass = 'droparea';
        event.preventDefault();
    }

    @HostListener('dragleave', ['$event']) onDragLeave(event: Event) {
        this.dragAreaClass = 'droparea';
        event.preventDefault();
    }
    @HostListener('drop', ['$event']) onDrop(event: any) {
        this.dragAreaClass = 'dragarea';
        event.preventDefault();
        event.stopPropagation();
        const files = <Array<File>>event.dataTransfer.files;
        if (files.length !== 1 && !this.multipleFiles) {
            //this.alert.error(this.translations.SELECT_ONLY_ONE);
        } else {
            // if (typeof this.maxSize === 'undefined') {
            //     this.maxSizeBytes = 10 * 1024 * 1024;
            //     this.maxSize = 10;
            // } else {
            //     this.maxSizeBytes = +(this.maxSize) * 1024 * 1024;
            // }
            if (this.isValidFileExtension(files)) {
                for (const file of files) {
                    if (file.size > this.maxSizeBytes) {
                        this.snackBar.open(this.fileTooLargeMessage, 'Close');
                        return;
                    }
                }

                if (!this.multipleFiles) {
                    this.fileName = files[0].name;
                } else {
                    this.fileName = files.length + ' files selected';
                }

                this.changeFile.emit(files);
            } else {
                this.snackBar.open('Invalid file extension. Use .jpg, .png or .mp4', 'Close');
                //console.log('invalid file extension');
            }
        }
    }

    private isValidFileExtension(files: any) {

        let exist = true;
        const extensions = (this.fileExt.split(','))
            .map((x) => x.toLocaleUpperCase().trim());
        for (let i = 0; i < files.length; i++) {

            const ext = files[i].name.toUpperCase().split('.').pop() || files[i].name;

            const exists = extensions.indexOf(ext);
            if (exists === -1) {
                exist = false;
            }
        }

        return exist;
    }

}
