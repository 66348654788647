import { Asset, AssetDTO, AssetTalentImages, AssetTalentImagesDTO } from '@no-kno/core/models/asset.model';
import { LookAlike, LookAlikeDTO, Talent, TalentDTO, TalentImageDTO, TalentImage, ReviewStatus, ReviewCode } from '@no-kno/core/models/talent.model';
import { Channel, ChannelDTO } from '@no-kno/core/models/channel.model';
import { Region, RegionDTO } from '@no-kno/core/models/region.model';
import { Market, MarketDTO } from '@no-kno/core/models/market.model';
import { ActiveMarket, ActiveMarketDTO } from '@no-kno/core/models/active-market.model';
import { Brand, BrandDTO } from '@no-kno/core/models/brand.model';
import { PagedResponse, PageStatus } from '@no-kno/core/models/api.model';
import { CustomChart, CustomChartDTO } from '@no-kno/core/models/custom-charts.model';
import { UserInfo, UserInfoDTO } from '@no-kno/core/models/user.model';
import { Benchmark, BenchmarkDTO, BenchmarkType } from '@no-kno/core/models/benchmark.model';
import { Tag, TagDTO } from '@no-kno/core/models/tag.model';
import { Audience, AudienceDTO, AudienceBelgium, AudienceBelgiumDTO } from '@no-kno/core/models/audience.model';
import { Geo, KeyValue, LabelValue } from '@no-kno/core/models/audience.model';
import { is } from 'date-fns/locale';

const defaultAssetThumbnailUrl = '/assets/images/white-square.png';
export const parseAssets = (item: AssetDTO): Asset => {
  const asset = {
    id: item.id.toString(),
    created: item.date_created ? new Date(item.date_created) : new Date(),
    updated: item.date_modified ? new Date(item.date_modified) : new Date(),
    published: item.date_published ? new Date(item.date_published) : new Date(),
    unpublished: item.date_unpublished ? new Date(item.date_unpublished) : null,
    type: item.type ?? '',
    name: item.name ?? '',
    description: item.description ?? '',
    views: item.view_count ?? 0,
    comments: item.like_count ?? 0,
    likes: item.like_count ?? 0,
    thumbnailUrl: item.thumbnail_url && item.thumbnail_url!='None' ? item.thumbnail_url : defaultAssetThumbnailUrl,
    brandId: item.brand_id?.toString() ?? '',
    brandName: item.brand_name ?? '',
    marketId: item.market_id?.toString() ?? '',
    marketName: item.market_name ?? '',
    marketCode: item.market_code ?? '',
    viewerUrl: item.viewer_url ?? '',
    originalUrl: item.original_url ?? '',
    s3Bucket: item.s3_bucket ?? '',
    s3Key: item.s3_key ?? '',
    extension: item.extension ?? '',
    status: item.status ?? '',
    activeMarketId: item.active_market_id || null,
    regions: item.regions ? item.regions.map(region => parseRegions(region)) : [],
    // talents: item.talents ? item.talents.map(talent => parseTalents(talent)) : [],
    talentImages: item.talent_images ? item.talent_images.map(talentImages => parseAssetsTalentImages(talentImages)) : [],
    channels: item.channels ? item.channels.map(channel => parseChannels(channel)) : [],
    tags: item.tags ? item.tags.map(tag => parseTags(tag)).sort((a, b) => (a.name > b.name) ? 1 : -1) : [],
  } as Asset;

  return asset;
};

export const parseAssetsTalentImages = (item: AssetTalentImagesDTO): AssetTalentImages => {
  const talentImages = {
    id: item.id.toString() ?? '',
    talentId: item.talent_id.toString() ?? '',
    gender: item.gender ?? '',
    estimatedAge: item.estimated_age || 0,
    domFacialFeatures: item.dom_facial_feat ?? '',
    imageUrl: item.image_url || '',
    isMainImage: item.is_main_image || false,
    role: item.role ?? 'unknown'
  } as AssetTalentImages;

  return talentImages;
};

export const parsePagination = (item: PagedResponse<any>): PageStatus => {
  const status = {
    current: item.current_page,
    size: item.items_per_page,
    pages: item.total_number_of_pages,
    total: item.total_number_of_items
  } as PageStatus;

  return status;
};

export const parseTalents = (item: TalentDTO): Talent => {
  const talent = {
    id: item.id.toString(),
    created: item.date_created ? new Date(item.date_created) : new Date(),
    updated: item.date_modified ? new Date(item.date_modified) : new Date(),
    original: item.is_original ?? '',
    assetId: item.asset_id?.toString() ?? '',
    gender: item.gender ?? '',
    minimumAge: item.minimum_age ?? 0,
    maximumAge: item.maximum_age ?? 0,
    // age: ((item.minimum_age + item.maximum_age) / 2).toFixed(),
    // race: item.race ?? '',
    description: item.description ?? '',
    imageUrl: item.images ? setTalentMainImage(item.images) : item.main_image_url? item.main_image_url : '',
    lookalikes: item.lookalikes ? item.lookalikes.map(lookalike => parseLookALike(lookalike)) : [],
    // asset: item.asset? parseTalentAsset(item.asset) : null,
    genderConfidence: item.gender_confidence ?? 0,
    estimatedAge: item.estimated_age ?? 0,
    domFacialFeatures: item.dom_facial_feat ?? '',
    domFacialFeaturesConfidence: item.dom_facial_feat_confidence ?? 0,
    secFacialFeatures: item.sec_facial_feat ?? '',
    secFacialFeaturesConfidence: item.sec_facial_feat_confidence ?? 0,
    images: item.images ? item.images.map(image => parseTalentImage(image)) : [],
    mergedIntoId: item.merged_into_id ?? 0,
    extraInfo: item.extra_info ?? {},
    reviewStatus: item.review_status,
    reviewCodes: item.review_codes ?? [],
    roles: item.roles ?? []
  } as Talent;

  return talent;
};

export const parseChannels = (item: ChannelDTO): Channel => {
  const channel = {
    id: item.id.toString(),
    created: item.date_created ? new Date(item.date_created) : new Date(),
    updated: item.date_modified ? new Date(item.date_modified) : new Date(),
    name: item.name ?? '',
    type: item.type ?? '',
    customerId: item.customer_id ?? null
  } as Channel;

  return channel;
};


export const parseRegions = (item: RegionDTO): Region => {
  const region = {
    id: item.id.toString(),
    created: item.date_created ? new Date(item.date_created) : new Date(),
    updated: item.date_modified ? new Date(item.date_modified) : new Date(),
    customerId: item.customer_id?.toString() ?? '',
    parentId: item.parent_id?.toString() ?? '',
    name: item.name ?? '',
    code: item.code ?? ''
  } as Region;

  return region;
};

export const parseTags = (item: TagDTO): Tag => {
  const tag = {
    id: item.id.toString(),
    customerId: item.customer_id?.toString() ?? '',
    name: item.name ?? ''
  } as Tag;

  return tag;
};

export const parseLookALike = (item: LookAlikeDTO): LookAlike => {
  const lookalike = {
    talentId: item.talent_id?.toString() ?? '',
    gender: item.gender ?? '',
    estimatedAge: item.estimated_age || 0,
    domFacialFeatures: item.dom_facial_feat ?? '',
    imageUrl: item.image_url ?? '',
  } as LookAlike;

  return lookalike;
};

// export const parseTalentAsset = (item: TalentAssetDTO): TalentAsset => {
//   const talentAsset = {
//     assetId: item.id?.toString() ?? '',
//     assetThumbnailUrl: item.thumbnail_url ?? '',
//     views: item.views ?? 0,
//     channels: item.channels ? item.channels.map(channel => parseChannels(channel)) : [],
//   } as TalentAsset;

//   return talentAsset;
// };

export const parseTalentImage = (item: TalentImageDTO): TalentImage => {
  const talentImage = {
    id: item.id?.toString() ?? '',
    assetId: item.asset_id?.toString() ?? '',
    talentId: item.talent_id?.toString() ?? '',
    assetThumbnailUrl: item.asset_thumbnail_url ?? '',
    imageUrl: item.image_url ?? '',
    isMainImage: item.is_main_image || false,
    views: item.views ?? 0,
    channels: item.channels ? item.channels.map(channel => parseChannels(channel)) : [],
    marketId: item.market_id ? item.market_id.toString() : '',
    marketName: item.market_name ?? ''
  } as TalentImage;

  return talentImage;
};

export const parseMarkets = (item: MarketDTO): Market => {
  const market = {
    id: item.id.toString(),
    brandId: item.brand_id?.toString() ?? '',
    name: item.name ?? '',
    regions: item.regions ? item.regions.map(region => parseRegions(region)) : []
  } as Market;

  return market;
};

export const parseActiveMarkets = (item: ActiveMarketDTO): ActiveMarket => {
  const market = {
    id: item.id.toString(),
    brandId: item.brand_id?.toString() ?? '',
    marketId: item.market_id?.toString() ?? '',
  } as ActiveMarket;

  return market;
};

export const parseBrands = (item: BrandDTO): Brand => {
  const brand = {
    id: item.id.toString(),
    created: item.date_created ? new Date(item.date_created) : new Date(),
    updated: item.date_modified ? new Date(item.date_modified) : new Date(),
    customerId: item.customer_id?.toString() ?? '',
    name: item.name ?? '',
    markets: item.markets ? item.markets.map(market => parseMarkets(market)) : []
  } as Brand;

  return brand;
};

export const parseCustomCharts = (item: CustomChartDTO): CustomChart => {
  const arr = item.title.split('::');

  return  {
    id: item.id.toString(),
    created: item.date_created ? new Date(item.date_created) : new Date(),
    updated: item.date_modified ? new Date(item.date_modified) : new Date(),
    title: arr.length === 2 ? arr[1] : item.title,
    category: arr.length === 2 ? arr[0] : 'No Category',
    activeMarketId: item.active_market_id.toString()
  } as CustomChart;
};

export const parseUser = (item: UserInfoDTO): UserInfo => {
  const user = {
    id: item.id.toString(),
    created: item.date_created ? new Date(item.date_created) : new Date(),
    updated: item.date_modified ? new Date(item.date_modified) : new Date(),
    customerId: item.customer_id?.toString() ?? '',
    firebaseId: item.firebase_id?.toString() ?? '',
    name: item.name ?? '',
    email: item.email ?? '',
    activeMarkets: item.active_markets ? item.active_markets.map(market => ({
      id: market.id,
      brandId: market.brand_id,
      marketId: market.market_id
    })) : []
  } as UserInfo;

  return user;
};

export const parseBenchmarks = (item: BenchmarkDTO): Benchmark => {
  /**
   * @description Parse Benchmark DTO to Benchmark
   * @param {BenchmarkDTO} item
   * @returns {Benchmark}
   */
  const benchmark = {
    id: item.id ? item.id.toString() : '-1', // -1 is a placeholder for a new benchmark
    created: item.date_created ? new Date(item.date_created) : new Date(),
    updated: item.date_modified ? new Date(item.date_modified) : new Date(),
    name: item.name ?? '',
    customerId: item.customer_id?.toString() ?? null,
    type: item.type ?? '',
    isShared: item.is_shared || false,
    genderMale: item.gender_male || 0,
    genderFemale: item.gender_female || 0,
    genderNonbinary: item.gender_nonbinary || 0,
    ageMinus18: item.age_minus_18 || 0,
    age18To24: item.age_18_to_24 || 0,
    age25To34: item.age_25_to_34 || 0,
    age35To44: item.age_35_to_44 || 0,
    age45To54: item.age_45_to_54 || 0,
    age55To64: item.age_55_to_64 || 0,
    age65AndOlder: item.age_65_and_older || 0,
    ethnA1: item.ethn_A1 || 0,
    ethnA2: item.ethn_A2 || 0,
    ethnB: item.ethn_B || 0,
    ethnL: item.ethn_L || 0,
    ethnW1: item.ethn_W1 || 0,
    ethnW2: item.ethn_W2 || 0
  } as Benchmark;

  return benchmark;
};

export const parseAudience = (item: AudienceDTO): Audience => {
  const audience = {
    id: item.id.toString(),
    created: item.date_created ? new Date(item.date_created) : new Date(),
    updated: item.date_modified ? new Date(item.date_modified) : new Date(),
    name: item.name ?? '',
    customerId: item.customer_id?.toString() ?? '',
    benchmarkId: item.benchmark_id?.toString() ?? '',
    country: item.country ?? ''
  } as Audience;
  return audience;
};

export const parseAudienceBelgium = (item: AudienceBelgiumDTO): AudienceBelgium => {
  console.log('parseAudienceBelgium', item);
  /*
  Take in an AudienceBelgiumDTO and return an AudienceBelgium
  AudienceBelgium has a fixed structure. When AudienceBelgiumDTO contains a value, then set the correscpoding selected to true.
  Specifically fo geo_lvl1:
  - set selected to true for the geo_lvl1 item, if all the geo_lvl2 items with parentId === geoLevel1.id have selected set to true.
  - set selected to false for the geo_lvl1 item, if not all the geo_lvl2 items with parentId === geoLevel1.id have selected set to true.
  - set intermediate to true for the geo_lvl1 item, if some of the geo_lvl2 items with parentId === geoLevel1.id have selected set to true.
  Calculate this by calculating the ratio of selected geo_lvl2 items to the total number of geo_lvl2 items with parentId === geoLevel1.id.
  */

  const isIntermediate = (geoLevel1: Geo, geoLevel2: Geo[], selectedGeoLevel2Codes: string[]): boolean => {
    // filter geo_lvl2 items with parentId === geoLevel1.id
    const filteredGeoLevel2 = geoLevel2.filter(geo => geo.parentId === geoLevel1.id);
    // check how many of those filtered geo_lvl2 items are in selectedGeoLevel2Codes
    const selectedGeoLevel2 = filteredGeoLevel2.filter(geo => selectedGeoLevel2Codes.includes(geo.id));
    // check if some of the filtered geo_lvl2 items are in selectedGeoLevel2Codes
    return selectedGeoLevel2.length > 0 && selectedGeoLevel2.length < filteredGeoLevel2.length;
  };

  const geoLevel2List = [
    { id: '10000', name: 'Antwerpen', parentId: '02000', selected: item.geo_lvl2.includes('10000') },
    { id: '20001', name: 'Vlaams-Brabant', parentId: '02000', selected: item.geo_lvl2.includes('20001') },
    { id: '30000', name: 'West-Vlaanderen', parentId: '02000', selected: item.geo_lvl2.includes('30000') },
    { id: '40000', name: 'Oost-Vlaanderen', parentId: '02000', selected: item.geo_lvl2.includes('40000') },
    { id: '70000', name: 'Limburg', parentId: '02000', selected: item.geo_lvl2.includes('70000') },
    { id: '20002', name: 'Brabant Wallon', parentId: '03000', selected: item.geo_lvl2.includes('20002') },
    { id: '50000', name: 'Hainaut', parentId: '03000', selected: item.geo_lvl2.includes('50000') },
    { id: '60000', name: 'Liège', parentId: '03000', selected: item.geo_lvl2.includes('60000') },
    { id: '80000', name: 'Luxembourg', parentId: '03000', selected: item.geo_lvl2.includes('80000') },
    { id: '90000', name: 'Namur', parentId: '03000', selected: item.geo_lvl2.includes('90000') },
    { id: '04000', name: 'Brussels Capital District', parentId: '04000', selected: item.geo_lvl2.includes('04000') },
  ];

  const geoLevel1List = [
    { id: '04000', name: 'Brussels Capital District',
      selected: item.geo_lvl1.includes('04000'),
      intermediate: isIntermediate({ id: '04000', name: 'Brussels Capital District' } as Geo, geoLevel2List, item.geo_lvl2) },
    { id: '02000', name: 'Flemish Region',
      selected: item.geo_lvl1.includes('02000'),
      intermediate: isIntermediate({ id: '02000', name: 'Flemish Region' } as Geo, geoLevel2List, item.geo_lvl2) },
    { id: '03000', name: 'Walloon Region',
      selected: item.geo_lvl1.includes('03000'),
      intermediate: isIntermediate({ id: '03000', name: 'Walloon Region' } as Geo, geoLevel2List, item.geo_lvl2) },
  ];
  const audience = {
    id: item.id.toString(),
    name: item.name ?? '',
    customerId: item.customer_id?.toString() ?? '',
    benchmarkId: item.benchmark_id?.toString() ?? '',
    country: item.country ?? 'Belgium',
    geo_lvl1: geoLevel1List,
    geo_lvl2: geoLevel2List,
    urban_lvl1: [
      { key: 'metropole', value: 'Major and central cities', selected: item.urban_lvl1.includes('metropole')},
      { key: 'city', value: 'City', selected: item.urban_lvl1.includes('city') },
      { key: 'municipality', value: 'Municipality', selected: item.urban_lvl1.includes('municipality') },
    ],
    age_lvl3: [
      { key: '-18', value: '-18', selected: item.age_lvl3.includes('-18') },
      { key: '18-24', value: '18-24', selected: item.age_lvl3.includes('18-24') },
      { key: '25-34', value: '25-34', selected: item.age_lvl3.includes('25-34') },
      { key: '35-44', value: '35-44', selected: item.age_lvl3.includes('35-44') },
      { key: '45-54', value: '45-54', selected: item.age_lvl3.includes('45-54') },
      { key: '55-64', value: '55-64', selected: item.age_lvl3.includes('55-64') },
      { key: '65+', value: '65+', selected: item.age_lvl3.includes('65+') },
    ],
    gender: [
      { key: 'M', value: 'Male', selected: item.gender.includes('M') },
      { key: 'F', value: 'Female', selected: item.gender.includes('F') },
    ],
    profOptions: {
      education: {label: 'Education', selected: item.education.length > 0},
      profStatus: {label: 'Professional status', selected: item.prof_status.length > 0},
      occupation: {label: 'Occupation', selected: item.occupation.length > 0},
      sector: {label: 'Sector', selected: item.sector.length > 0},
    },
    education: [ // ['high', 'low', 'mid', 'nap']
      { key: 'high', value: 'High', selected: item.education.includes('high') },
      { key: 'mid', value: 'Medium', selected: item.education.includes('mid') },
      { key: 'low', value: 'Low', selected: item.education.includes('low') },
      { key: 'nap', value: 'Not applicable', selected: item.education.includes('nap') },
    ],
    profStatus: [ // ['civil_servant', 'employee', 'self_employed', 'unempl_inact', 'worker']
      { key: 'worker', value: 'Worker', selected: item.prof_status.includes('worker') },
      { key: 'employee', value: 'Employee', selected: item.prof_status.includes('employee') },
      { key: 'civil_servant', value: 'Civil servant', selected: item.prof_status.includes('civil_servant') },
      { key: 'self_employed', value: 'Self-employed', selected: item.prof_status.includes('self_employed') },
      { key: 'unempl_inact', value: 'Inactive or unemployed', selected: item.prof_status.includes('unempl_inact') },
    ],
    occupation: [
      // ['clerical_support_workers', 'craft_trade_workers', 'elementary_occupations', 'machine_operator_assemblers',
      // 'managers', 'professionals', 'service_sales_workers', 'technicians']
      { key: 'managers', value: 'Managers (ISCO 1)', selected: item.occupation.includes('managers') },
      { key: 'professionals', value: 'Professionals (ISCO 2)', selected: item.occupation.includes('professionals') },
      { key: 'technicians', value: 'Technicians and Associate Professionals (ISCO 3)', selected: item.occupation.includes('technicians') },
      { key: 'clerical_support_workers', value: 'Clerical Support Workers (ISCO 4)', selected: item.occupation.includes('clerical_support_workers') },
      { key: 'service_sales_workers', value: 'Service and Sales Workers (ISCO 5)', selected: item.occupation.includes('service_sales_workers') },
      { key: 'craft_trade_workers', value: 'Craft and Related Trades Workers (ISCO 7)', selected: item.occupation.includes('craft_trade_workers') },
      { key: 'machine_operator_assemblers', value: 'Plant and Machine Operators, and Assemblers (ISCO 8)', selected: item.occupation.includes('machine_operator_assemblers') },
      { key: 'elementary_occupations', value: 'Elementary Occupations (ISCO 9)', selected: item.occupation.includes('elementary_occupations') },
    ],
    sector: [
      // ['accomm_food', 'admin_support', 'arts_entertainment', 'construction', 'education', 'extra_territorial',
      // 'financial_insurance', 'health_social', 'info_communication', 'manufacturing', 'other_services', 'professional_scientific',
      // 'public_admin_defence', 'transport_storage', 'wholesale_retail']
      { key: 'manufacturing', value: 'Manufacturing (NACEBEL C)', selected: item.sector.includes('manufacturing') },
      { key: 'construction', value: 'Construction (NACEBEL F)', selected: item.sector.includes('construction') },
      { key: 'wholesale_retail', value: 'Wholesale and retail trade; repair of motor vehicles and motorcycles (NACEBEL G)', selected: item.sector.includes('wholesale_retail') },
      { key: 'transport_storage', value: 'Transportation and storage (NACEBEL H)', selected: item.sector.includes('transport_storage') },
      { key: 'accomm_food', value: 'Accommodation and food service activities (NACEBEL I)', selected: item.sector.includes('accomm_food') },
      { key: 'info_communication', value: 'Information and communication (NACEBEL J)', selected: item.sector.includes('info_communication') },
      { key: 'financial_insurance', value: 'Financial and insurance activities (NACEBEL K)', selected: item.sector.includes('financial_insurance') },
      { key: 'professional_scientific', value: 'Professional, scientific and technical activities (NACEBEL M)', selected: item.sector.includes('professional_scientific') },
      { key: 'admin_support', value: 'Administrative and support service activities (NACEBEL N)', selected: item.sector.includes('admin_support') },
      { key: 'public_admin_defence', value: 'Public administration and defence; compulsory social security (NACEBEL O)', selected: item.sector.includes('public_admin_defence') },
      { key: 'education', value: 'Education (NACEBEL P)', selected: item.sector.includes('education') },
      { key: 'health_social', value: 'Human health and social work activities (NACEBEL Q)', selected: item.sector.includes('health_social') },
      { key: 'arts_entertainment', value: 'Arts, entertainment and recreation (NACEBEL R)', selected: item.sector.includes('arts_entertainment') },
      { key: 'other_services', value: 'Other service activities (NACEBEL S)', selected: item.sector.includes('other_services') },
      { key: 'extra_territorial', value: 'Extra-territorial organizations and bodies (NACEBEL U)', selected: item.sector.includes('extra_territorial') },
    ],
  } as AudienceBelgium;
  console.log('parseAudienceBelgium, result:', audience);
  return audience;
};

export const convertAudienceBelgiumToDTO = (item: AudienceBelgium): AudienceBelgiumDTO => {
  // profOption = the first key of the item.profOptions object where selected is true
  const profOption = Object.keys(item.profOptions).find(key => item.profOptions[key].selected);

  const audience = {
    id: Number(item.id),
    name: item.name,
    customer_id: Number(item.customerId),
    benchmark_id: Number(item.benchmarkId),
    country: item.country,
    geo_lvl1: item.geo_lvl1.filter(geo => geo.selected).map(geo => geo.id),
    geo_lvl2: item.geo_lvl2.filter(geo => geo.selected).map(geo => geo.id),
    urban_lvl1: item.urban_lvl1.filter(urban => urban.selected).map(urban => urban.key),
    age_lvl3: item.age_lvl3.filter(age => age.selected).map(age => age.key),
    gender: item.gender.filter(gender => gender.selected).map(gender => gender.key),
    education: profOption === 'education' ? item.education.filter(education => education.selected).map(education => education.key) : [],
    prof_status: profOption === 'profStatus' ? item.profStatus.filter(profStatus => profStatus.selected).map(profStatus => profStatus.key) : [],
    occupation: profOption === 'occupation' ? item.occupation.filter(occupation => occupation.selected).map(occupation => occupation.key) : [],
    sector: profOption === 'sector' ? item.sector.filter(sector => sector.selected).map(sector => sector.key) : [],
  } as AudienceBelgiumDTO;
  return audience;
};


export const organizeCustomCharts = (list: CustomChart[]): { [category: string]: CustomChart[] } => list.reduce((prev, curr) => {
  if (!(curr.category in prev)) {
    prev[curr.category] = [];
  }

  prev[curr.category].push(curr);

  return prev;
}, {} as { [category: string]: CustomChart[] });


const setTalentMainImage = (images: TalentImageDTO[]): string => {
  const mainImage = images.find(img => img.is_main_image);

  return mainImage?.image_url || '';
};
