import { Injectable } from '@angular/core';

import { last, Observable, shareReplay, lastValueFrom } from 'rxjs';

import { Asset } from '@no-kno/core/models/asset.model';
import { PagedData } from '@no-kno/core/models/api.model';

import { FilterData } from '../models/filter.model';
import { ApiService } from './api.service';
import { Talent, TalentsFilter } from '@no-kno/core/models/talent.model';
import { Tag } from '@no-kno/core/models/tag.model';
import { Benchmark, BenchmarkDTO, BenchmarkType } from '@no-kno/core/models/benchmark.model';
import { ActiveMarketOption, ActiveMarketDTO, ActiveMarket } from '@no-kno/core/models/active-market.model';

@Injectable()
export class DataService {

  channels$ = this.api.getChannels().pipe(shareReplay(1));
  markets$ = this.api.getMarkets().pipe(shareReplay(1));
  brands$ = this.api.getBrands().pipe(shareReplay(1));
  regions$ = this.api.getRegions().pipe(shareReplay(1));
  //benchmarks$ = this.api.getBenchmarks().pipe(shareReplay(1));


  constructor(private api: ApiService) {
  }


  getAssets(filter?: FilterData, sort: string = '', page: number = 1, size: number = 50, search: string = ''): Observable<PagedData<Asset[]>> {
    return this.api.getAssets(filter || {} as FilterData, sort, page + 1, size, search);
  }

  getAssetById(id: string): Observable<Asset> {
    return this.api.getAssetById(id);
  }

  getTalents(filter?: FilterData, sort: string = '', page: number = 1, size: number = 50, extraFilter: TalentsFilter = {}): Observable<PagedData<Talent[]>> {
    return this.api.getTalents(filter || {} as FilterData, sort, page + 1, size, extraFilter);
  }

  getTalentById(id: string): Observable<Talent | null> {
    return this.api.getTalentById(id);
  }

  searchTags(query: string): Observable<Tag[]> {
    return this.api.getTags(query);
  }

  getBenchmarks(): Observable<any> {
    return this.api.getBenchmarks().pipe(shareReplay(1));
  }

  async getActiveMarketOptions(): Promise<ActiveMarketOption[]> {
    /**
     * Get the brands for this customerID from the API
     * Loop through the brands and get the brand name, brand id and markets name, market id for each brand
     * Get the active markets from the API
     *
     * Brand:
     *  id: string;
     *  customerId: string;
     *  name: string;
     *  markets: Market[];
     *
     * Market:
     *  id: string;
     *  brandId: string;
     *  name: string;
     *  regions: Region[];
     */
    const brands = await lastValueFrom(this.api.getBrands());
    const activeMarkets = await lastValueFrom(this.api.getActiveMarkets());

    const activeMarketOptions: ActiveMarketOption[] = [];
    brands.forEach(brand => {
      brand.markets.forEach(market => {
        const activeMarket = activeMarkets.find(am => am.brandId === brand.id && am.marketId === market.id);
        if (activeMarket) {
          activeMarketOptions.push({
            id: activeMarket.id,
            name: `${brand.name} | ${market.name}`
          } as ActiveMarketOption);
        }
      });
    });
    return activeMarketOptions;
  }
}
