import { DatabaseDTOItem, DatabaseItem } from './database.model';

export interface Benchmark extends DatabaseItem {
  name: string;
  customerId?: string;
  isShared: boolean;
  type: BenchmarkType;
  genderMale: number;
  genderFemale: number;
  genderNonbinary: number;
  ageMinus18: number;
  age18To24: number;
  age25To34: number;
  age35To44: number;
  age45To54: number;
  age55To64: number;
  age65AndOlder: number;
  ethnA1: number;
  ethnA2: number;
  ethnB: number;
  ethnL: number;
  ethnW1: number;
  ethnW2: number;
}

export interface BenchmarkDTO extends DatabaseDTOItem {
  name: string;
  customer_id?: number;
  type: BenchmarkType;
  is_shared: boolean;
  gender_male: number;
  gender_female: number;
  gender_nonbinary: number;
  age_minus_18: number;
  age_18_to_24: number;
  age_25_to_34: number;
  age_35_to_44: number;
  age_45_to_54: number;
  age_55_to_64: number;
  age_65_and_older: number;
  ethn_A1: number;
  ethn_A2: number;
  ethn_B: number;
  ethn_L: number;
  ethn_W1: number;
  ethn_W2: number;
}

export enum BenchmarkType {
  None = '',
  Creative = 'creative',
  Audience = 'audience',
  Custom = 'custom',
};

export const benchmarkLabels = [
  {type: '', label: 'No benchmark'},
  {type: 'audience', label: 'Audience benchmark'},
  {type: 'custom', label: 'Custom benchmark'},
  {type: 'creative', label: 'Creative bechmark'},
];

export interface BenchmarkOption {
  type: BenchmarkType;
  label: string;
}


export const getNewBenchmark = (customerId: string): Benchmark => ({
  id: '',
  name: '',
  customerId,
  isShared: false,
  type: BenchmarkType.Custom,
  genderMale: 0,
  genderFemale: 0,
  genderNonbinary: 0,
  ageMinus18: 0,
  age18To24: 0,
  age25To34: 0,
  age35To44: 0,
  age45To54: 0,
  age55To64: 0,
  age65AndOlder: 0,
  ethnA1: 0,
  ethnA2: 0,
  ethnB: 0,
  ethnL: 0,
  ethnW1: 0,
  ethnW2: 0,
});

export const ethnicNameMap = {
  ethn_A1: 'East Asian',
  ethn_A2: 'South & West Asian',
  ethn_B: 'Black',
  ethn_L: 'Latine/Hispanic',
  ethn_W1: 'White',
  ethn_W2: 'Middle Eastern/Mediterranean European'
};

export const ethnicCodeNameMap = {
  A1: 'East Asian',
  A2: 'South & West Asian',
  B: 'Black',
  L: 'Latine/Hispanic',
  W1: 'White',
  W2: 'Middle Eastern/Mediterranean European'
};
