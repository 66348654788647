import { ChartType } from 'angular-google-charts';

export interface ChartData {
  [chart: string]: ChartContentData;
}

export interface ChartContentData {
  feature: string;
  title: string;
  type: ChartType;
  data: any[];
  columns?: any[];
  options?: any;
  view?: number;
  explanation?: ChartExplanation;
  tooltip?: { key: string; value: number }[][];
  actual?: number;
  objective?: number;
  benchmark?: number;
  tableColumns?: any[];
  tableData: any[];
};

export interface ChartFeature {
  feature: string;
  title: string;
  type: ChartType;
  apiType: string;
  explanation: ChartExplanation;
};

export interface ChartExplanation {
  description: string;
  source: string;
}

export enum ChartApiType {
  ObjectivesDistribution = 'objectives/distribution',
  ObjectivesGeo = 'objectives/geo',
  Distribution = 'distribution',
  BenchmarkDistribution = 'benchmark',
  Timeline = 'timeline',
  Cross = 'cross'
}

/**
 * STYLE_ROLE_COLUMN_INDEX is used by benchmarks, to identify the column containing style info
 */
export const STYLE_ROLE_COLUMN_INDEX = 2;

export interface ChartHeader {
  id: string;
  label: string;
  pattern: string;
  type: 'string' | 'number' | 'date' | 'datetime' | 'timeofday';
  p: {
    role: 'style' | 'annotation' | 'tooltip' | 'scope' | 'domain' | 'measure';
  };
}

export interface ChartRowValue {
  v: string | number | Date | [number, number];
}

export interface ChartFilter {
  role: string;
  benchmarkId: string;
}
