<div draggable="true" ngClass="{{dragAreaClass}}">
    <div class="container">
        <div class="drop-message">Drag and drop file (jpg, png, mp4)</div>
        <div class="or-message">or</div>
        <div>
            <button  mat-flat-button class="upload-file-btn" href="javascript:void(0)" (click)="file.click()">
                Choose file
            </button>
        </div>
        <div class="file-name"> {{ fileName || maxFileSizeMessage}} </div>
        
    </div>
   
    <input id="file" type="file" required #file (change)="onFileChange($event)" style="display:none"
        accept="{{ accept }}" [attr.multiple]="multipleFiles ? true : null" />
</div>