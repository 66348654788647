
export interface FilterData {
  markets: string[];
  regions: string[];
  brands: string[];
  channels: string[];
  tags: string[];
  date: {
    start: Date | null;
    end: Date | null;
  };
  role?: string;
  benchmarkId?: string;
}

export enum FilterViews {
  Filter = 'filter',
  Settings = 'settings',
}

export enum CustomDateType {
  Start = 'start',
  End = 'end',
}
